<template lang="pug">
.page
  el-card
    el-input(placeholder="按名称搜索门店" @keyup.enter.native="search" @clear="search" type="search" v-model="storeTitle")
  .storeList
    .storeItem(v-for="i in storeList" :class='{isAvtive:pickedList.includes(i.id),isHide:i.hideStatus==1,isPC:!isMobile}')
      img.logo(:src="i.storeLogo")
      .info
        .title {{i.storeTitle}}
        .desc 距离 {{i.distance.toFixed(2)}} km
      .checkbox(@click="handlePick(i)")
        el-icon
          Check
  .pagination
    el-pagination(layout="total, prev, pager, next" background v-model:page-size="pagination.size" :pager-count="isMobile?3:5" :page-sizes="[10, 20, 50, 100]" :total="pagination.total" v-model:current-page="pagination.page" @current-change="getList")
  .footer(@click="addStore") 添加门店

el-dialog(v-model="isShowBatchUpdateFloorDialog" title="添加门店")
  el-form
    el-form-item(label="已选中门店")
      el-tag(v-for="i in pickedItemList" :label="i.id" style="margin: 5px 5px 0 0 ;") {{i.storeTitle}}
    el-form-item(label="区域")
      el-radio-group(v-model="batchUpdateFloorDialogData.region")
        el-radio-button(v-for="i in regionList" :label="i.id") {{i.regionTitle}}
    el-form-item(label="楼层")
      el-radio-group(v-model="batchUpdateFloorDialogData.floor")
        el-radio-button(v-for="i in floorList" :label="i.id") {{i.floorTitle}}
  template(#footer)
    el-button(@click="isShowBatchUpdateFloorDialog=false") 取消
    el-button(type="primary" :disabled="!!errorMessageForBatchUpdateFloor" @click="handleSubmitBatchUpdateFloor") {{errorMessageForBatchUpdateFloor||'确定'}}
</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { Check } from '@element-plus/icons-vue'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'

const { fetch, router, message, ElMessageBox } = inject('global')
const storeTitle = ref('')
const filter = ref({
  region: '',
  floor: ''
})

const regionList = ref([])
const floorList = computed(() => {
  return regionList.value?.find(i => i.id === batchUpdateFloorDialogData.value.region)?.floors || []
})
const storeList = ref([])
const pickedList = ref([])
const pickedItemList = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 10
})

// 获取区域和楼层
function getConfig() {
  return fetch
    .get(`/boom-center-product-service/sysAdmin/commercialPlaza/facilities/${currentMarketId.value}`)
    .then(res => {
      regionList.value = res.regionList
      filter.value.region = regionList.value?.[0]?.id || ''
      return
    })
}
// 获取门店列表
function getList() {
  let params = {
    storeTitle: storeTitle.value,
    ...pagination.value
  }
  fetch
    .get(`/boom-center-search-service/sysAdmin/store/commercialPlazaStore/page/${currentMarketId.value}`, { params })
    .then(res => {
      storeList.value = res.list
      pagination.value.total = Number(res.total)
    })
}
// 搜索
function search() {
  pagination.value.page = 1
  getList()
}
// 添加门店
function addStore() {
  if (!pickedList.value.length) {
    message.error('请先选择门店')
    return
  }
  isShowBatchUpdateFloorDialog.value = true
}

function handlePick(item) {
  const n = pickedList.value.findIndex(i => i === item.id)
  if (n >= 0) {
    pickedList.value.splice(n, 1)
    pickedItemList.value.splice(n, 1)
  } else {
    pickedList.value.push(item.id)
    pickedItemList.value.push(item)
  }
}

const allPickedItem = computed(() => {
  return storeList.value.filter(i => pickedList.value?.includes(i.id)) || []
})
const isShowBatchUpdateFloorDialog = ref(false)
const batchUpdateFloorDialogData = ref({
  region: '',
  floor: ''
})
const errorMessageForBatchUpdateFloor = computed(() => {
  if (!batchUpdateFloorDialogData.value.region) return '请选择区域'
  if (!batchUpdateFloorDialogData.value.floor) return '请选择楼层'
  return ''
})
function handleSubmitBatchUpdateFloor() {
  if (errorMessageForBatchUpdateFloor.value) {
    message.warning(errorMessageForBatchUpdateFloor.value)
  }

  fetch
    .post(
      `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/addRegionRelation/${currentMarketId.value}`,
      {
        commercialPlazaId: currentMarketId.value,
        commercialPlazaRegionId: batchUpdateFloorDialogData.value.floor || 0,
        stores: allPickedItem.value.map(v => {
          return {
            businessId: v.businessId,
            storeId: v.id
          }
        })
      }
    )
    .then(res => {
      message.success('已提交审核，请耐心等待')
      isShowBatchUpdateFloorDialog.value = false
      router.go(-1)
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

async function initData() {
  pickedList.value = []
  pagination.value.page = 1
  await getConfig()
  getList()
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.storeList {
  --gutter: 10px;
  --logoSize: 80px;
  --checkboxSize: 50px;
  --size-text-title: 18px;
  --size-text-desc: 14px;
  display: flex;
  flex-wrap: wrap;
  .storeItem {
    .trans();
    margin: var(--gutter);
    padding: var(--gutter);
    background: white;
    border-radius: var(--gutter);
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 300px;
    border: 1px solid;
    border-color: var(--color-border);
    &:hover {
      box-shadow: 0 0 12px var(--color-border);
    }
    &.isPC {
      width: calc(33% - 20px);
      flex-basis: auto;
      flex-grow: unset;
    }
    .logo {
      width: var(--logoSize);
      height: var(--logoSize);
      margin-right: var(--gutter);
      flex-shrink: 0;
    }
    .info {
      flex-grow: 1;
      .title {
        font-size: var(--size-text-title);
        color: var(--color-text-title);
      }
      .desc {
        font-size: var(--size-text-desc);
        color: var(--color-text-desc);
      }
    }
    .checkbox {
      .flexCenter();
      .rounded();
      flex-shrink: 0;
      cursor: pointer;
      width: var(--checkboxSize);
      height: var(--checkboxSize);
      font-size: calc(var(--checkboxSize) / 2);
      color: #999;
      &:hover {
        background: var(--color-pink);
        color: white;
      }
    }
    &.isAvtive {
      border-color: var(--color-primary);
      .checkbox {
        background: var(--color-primary);
        color: white;
      }
    }
    &.isHide {
      filter: grayscale(80%);
      filter: gray;
      opacity: 0.7;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.footer {
  text-align: center;
  color: #fff;
  background-color: var(--color-red);
  position: fixed;
  bottom: 0;
  z-index: 3000;
  width: 100%;
  line-height: 60px;
  cursor: pointer;
}

.isMobile {
  .storeList {
    --gutter: 10px;
    --logoSize: 40px;
    --checkboxSize: 30px;
    --size-text-title: 14px;
    --size-text-desc: 12px;
  }
}
</style>
